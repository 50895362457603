<template>
  <div class="c-main">
    <h2 class="c-title c-title--page">{{ $t('displayTemplate.add') }}</h2>
    <dtSet @submit="update($event)"></dtSet>
  </div>
</template>

<script>
export default {
  name: 'admin-displaySet-add',
  components: {
    dtSet: require('./set.vue').default,
  },
  methods: {
    update: function (set) {
      // TODO: set owner id ?
      this.$notify.saveAsync(
        this.$t('displayTemplate'),
        this.$store.dispatch('displayTemplate.add', {data: set}),
        () => {
          this.$navigation.goto({name: 'admin.user.displayTemplates'});
        }
      );
    },
  },
};
</script>

<style>
</style>
