<template>
  <div class="c-main">
    <h2 class="c-title c-title--page">{{$t('menu.displayTemplates')}}</h2>
    <grid :config="{searchFields: ['name', 'description']}" :data="templates">
      <template v-slot:gridAdd>
        <router-link :to="{ name: 'admin.user.displayTemplate.add'}"
                     class="c-button c-button--secondary c-button--large c-button--icon">
          <svg role="img" class="c-button__icon">
            <use xlink:href="images/svg-symbols.svg#plus-icon"></use>
          </svg>
          <span class="c-button__label">{{$t("displayTemplate.add")}}</span>
        </router-link>
      </template>
      <template v-slot:gridHeader>
        <div class="o-layout c-list__row u-padding-horizontal-small">
          <div class="o-layout__item u-1-of-4-at-small">
            {{$t('name')}}
          </div>
          <div class="o-layout__item u-2-of-4-at-small">
            {{$t('description')}}
          </div>
          <div class="o-layout__item u-1-of-4-at-small">
            {{$t('type')}}
          </div>
        </div>
      </template>
      <template v-slot:gridData="{element}">
        <div class="o-layout__item u-1-of-4-at-small c-list__main-cell">
          <router-link class="c-list__link" :to="{ name: 'admin.user.displayTemplate.view',
           params: {templateId: element.id}}">
            {{element.name}}
          </router-link>
        </div>
        <div class="o-layout__item u-2-of-4-at-small c-list__main-cell">
          <router-link class="c-list__link" :to="{ name: 'admin.user.displayTemplate.view',
           params: {templateId: element.id}}">
            {{element.description}}
          </router-link>
        </div>
        <div class="o-layout__item u-1-of-4-at-small">
          {{element.global ? $t('scoreexpress') : $t('custom')}}
        </div>
      </template>
    </grid>
  </div>
</template>

<script>
  import sortBy from 'lodash/sortBy';
  import map from 'lodash/map';

  export default {
    name: 'admin-displayTemplates-list',
    components: {
      grid: require('client/components/dataGrid.vue').default,
    },
    computed: {
      templates: function () {
        return map(sortBy(this.$store.state.displayTemplates.items, 'name'), item => {
          return {
            name: item.name,
            description: item.description,
            id: item.id,
            global: item.global,
          }
        });
      }
    },
    methods: {}
  };
</script>
